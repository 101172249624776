@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500;800&display=swap');

@font-face {
    font-family: 'openmoji';
    src: url('../../fonts/openmoji-black-webfont.woff2') format('woff2'),
         url('../../fonts/openmoji-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


.App {
  text-align: center;

  font-family: 'openmoji', 'Work Sans', 'Avenir Next', 'Helvetica Neue';
}

.App-header {
  background-color: #302b2f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.Button {
  display: flex;
  justify-content: center;
  height: 75px;
  width: 75px;
  border: 1px solid #555;
  font-size: 50px;
  line-height: 0;
  border-radius: 3px;
  cursor: pointer;
}
