@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@500;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.StartOver{position:fixed;right:20px;bottom:20px}

.RestaurantCard{position:absolute;top:0;left:0;right:0;bottom:0;background:#1f1c1e;border-radius:3px;overflow:hidden;will-change:transform;transition:all 0.3s ease-in-out;cursor:-webkit-grab;cursor:grab}.RestaurantCard__Image{width:100%;height:50%;overflow:hidden;background-size:cover}.RestaurantCard__Rating{margin-bottom:10px}.RestaurantCard a{color:#eee}.RestaurantCard--moving{transition:none;cursor:-webkit-grabbing;cursor:grabbing}.RestaurantCard::after{content:"";display:block;position:absolute;top:0;left:0;right:0;bottom:0;pointer-events:none;opacity:0;transition:opacity 100ms ease-out}.RestaurantCard--approved::after,.RestaurantCard--rejected::after{opacity:0.5}.RestaurantCard--approved::after{background:#1d351d}.RestaurantCard--rejected::after{background:#4d1d1d}

.Winner{position:absolute;top:0;left:0;right:0;bottom:0;background:#012;padding:20px;z-index:100}.Winner .RestaurantCard{position:relative}

.Share{position:fixed;left:20px;bottom:20px}.Share__Modal{position:fixed;top:0;left:0;right:0;bottom:0;display:flex;justify-content:center;align-items:center;background:#333D;z-index:100}

.UpNext{position:relative;width:90vw;height:80vh}.Actions{margin-top:10px;display:flex}.Actions .Button{margin:20px}

@font-face {
    font-family: 'openmoji';
    src: url(/static/media/openmoji-black-webfont.a58005b8.woff2) format('woff2'),
         url(/static/media/openmoji-black-webfont.1ce5e487.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}


.App {
  text-align: center;

  font-family: 'openmoji', 'Work Sans', 'Avenir Next', 'Helvetica Neue';
}

.App-header {
  background-color: #302b2f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.Button {
  display: flex;
  justify-content: center;
  height: 75px;
  width: 75px;
  border: 1px solid #555;
  font-size: 50px;
  line-height: 0;
  border-radius: 3px;
  cursor: pointer;
}

