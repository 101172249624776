.UpNext {
  position: relative;

  width: 90vw;
  height: 80vh;
}

.Actions {
  margin-top: 10px;
  display: flex;

  .Button {
    margin: 20px;
  }
}
